import React from "react"
import Layout from "../components/layout/layout"
import "./frontpage.scss"
import LatestPosts from "../components/blocks/latest-posts";

export default () => {

  return (
    <Layout>
      <main>
        <section>

          <LatestPosts/>

        </section>
      </main>
    </Layout>
  )
};
