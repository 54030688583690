import React from "react";
import "./article-teaser.scss"
import { GatsbyImage } from "gatsby-plugin-image";
import {Link} from "gatsby";

const ArticleTeaser = (props) => {

  return (
    <Link className="article-teaser" to={props.link}>
      <GatsbyImage image={{...props.image}} />
      <div className="article-info">
        <h3>{props.title}</h3>
      </div>
    </Link>
  )
};

export default ArticleTeaser;
